<template>
  <div class='JZContainer'>
    <content-block  title='我的企业'>
      <TableWithPagination :tableData="targetLists"
        ref="orderTable"
        :columnList="columnList"
        row-key='supplierId'
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
      ></TableWithPagination>
    </content-block>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import {getUserSupplierList, setDefaultSupplier} from '@/api/userCenter';
export default {
  name: 'Company',
  mixins: [InfoMixin],
  created () {
    this.$store.dispatch('changeDefaultActive', this.$route.name);
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      getUserSupplierList(currentParams).then(res=>{
        this.targetLists = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    handleDetail (info) {
      this.$router.push({
        name: 'DetailCompany',
        query: {
          id: info.supplierId,
        },
      });
    },
    handleSet (info) {
      let settings = Object.assign({}, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm('是否要设置该企业为默认', '', settings).then(() => {
        // 这里调用接口
        setDefaultSupplier({supplierId: info.supplierId}).then(() => {
          this.handleSearch();
        });
      });
    },
  },
  data () {
    return {
      targetLists: [{id: 1}],
      columnList: [
        {
          tooltip: true,
          label: '企业名称',
          minWidth: '110px',
          prop: 'supplierName',
        },
        {
          tooltip: true,
          label: '供应商认证状态',
          minWidth: '150px',
          prop: 'supplierCertifiedFlag',
          format: (val) => {
            return val === true ? '是' : val === false ? '否' : '--';
          },
        },
        {
          tooltip: true,
          label: '是否默认',
          minWidth: '110px',
          prop: 'defaultFlag',
          format: (val) => {
            return val === true ? '是' : val === false ? '否' : '--';
          },
        },
        {
          tooltip: true,
          label: '我的企业身份',
          minWidth: '110px',
          prop: 'identity',
          format: (val) => {
            return val === 'ADMIN' ? '管理员' : val === 'NORMAL' ? '普通用户' : '--';
          },
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '150px',
          prop: 'ledgerName',
          render: (h, {row}) => {
            let actions = [];
            if (!row.defaultFlag) {
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleSet.bind(this, row),
                },
              }, '默认设置'));
            }
            actions.push(h('span', {
              domProps: {
                className: 'blue',
              },
              on: {
                click: this.handleDetail.bind(this, row),
              },
            }, '查看'));
            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
